.requirementsCard {
  @include contentBlock;
  // overflow: auto;

  &.outlined {
    border: $generic-border--dotted;
    justify-content: center;
    color: $primary;

    &:hover {
      transform: scale(1.01);
      border-color: $secondary;
    }

    .card__content {
      text-align: center;

      svg {
        height: 2rem;
      }
    }
  }
}

.skillList {
  width: 100%;
  height: 10rem;
  overflow: scroll;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: $border-radius;
  background-color: white;
  box-shadow: $box-shadow;

  ul li {
    padding: 0.7rem 0;
    border-bottom: $generic-border;
    color: $gray;
  }
}
