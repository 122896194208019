.applicantListContainer {
  grid-column: span 1;
  grid-row: span 2;

  @include mq('1200px') {
    grid-column: span 2;
  }

  .appCard {
    width: 100%;
    background: white;
    margin: 2em 0;
    border-radius: $border-radius;
    padding: 27px 35px;

    &.appCard--filterable {
      padding: 27px 0;
    }

    .appFilter {
      margin-left: 35px;
    }

    .applicantWrapper {
      padding: 0 35px;
      position: relative;

      &.applicantWrapper--active {
        background-color: #eaebee;
      }

      .applicant {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .applicantAvatar {
          height: 32px;
          width: 32px;
          object-fit: cover;
          border-radius: 100%;
          margin: 0 13px;
        }

        .applicantDetails {
          line-height: 0;
        }

        .applicantActions {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .action__icon {
            height: 2rem;
            width: 2rem;
            margin-left: 0.765rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            transition: all 0.3s ease-in-out;

            &.active {
              background-color: $secondary;
              svg {
                fill: white;
              }
            }

            &:hover {
              cursor: pointer;
              background-color: #fec72e97;
              fill: white;
            }

            svg {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }
  }
}

.applicantModal__inner {
  // background-color: lightpink;
  height: 45vh;
  overflow: scroll;
}
