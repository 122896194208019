.statusPill {
  padding: .5em .9em;
  color: white;
  border-radius: 5em;
  font-size: 12px;
  background-color: $info;

  &.statusPill--Warning {
    background-color: $secondary;
  }

  &.statusPill--Success {
    background-color: $success;
  }

  &.statusPill--Danger {
    background-color: $danger;
  }
}