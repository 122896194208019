.previewCard {
  @include contentBlock;
  // max-height: 60vh;
  height: fit-content;
  overflow: hidden;
  position: relative;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   width: 100%;
  //   height: 120px;
  //   background: linear-gradient(
  //     180deg
  //     , rgba(255, 255, 255, 0) -10%, white 100%);
  // }

  .icon-button {
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 1rem;
    transform: translateX(-50%);
    background-color: $secondary;
    display: none;
    border: none;
    // opacity: 1;

    // &--hidden {
    //   opacity: 0;
    // }
  }

  &.true {
    max-height: fit-content;
  }

  &.false {
    max-height: 60vh;
  }

  .previewDesc {
    font-size: 12px;
    line-height: 1.5;
    position: relative;
  }

  .previewSummary {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .previewMedia {
      width: 100%;
      height: 15rem;
      object-fit: cover;
    }

    .previewMeta {
      width: 50%;

      .metaContainer {
        display: flex;
        justify-content: space-between;
        width: 80%;

        p {
          padding: 0.5em;
          color: $gray;

          &.metaBlock--status {
            border-left: 0.2em solid $info;
          }

          &.metaBlock--visibility {
            border-left: 0.2em solid $success;
          }

          &.metaBlock--createdBy {
            border-left: 0.2em solid $secondary;
          }
        }
      }
    }
    .detailsContainer {
      margin-top: 1.1em;
      display: flex;
      width: 80%;
      justify-content: space-between;

      img {
        width: 21px;
        object-fit: contain;
        margin-right: 1em;
      }

      div {
        display: flex;
        align-items: center;
        color: $primary;
        font-size: 12px;
      }
    }
  }
  .previewRequirements {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 2em;
  }
}

.reqItem {
  grid-column: span 1;
  color: $gray;

  h3 {
    margin-bottom: 2em;
    color: $primary;
  }

  &.reqItem-education {
    ul li {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      img {
        width: 21px;
        object-fit: contain;
        margin-right: 1em;
      }
    }
  }

  &.reqItem-experience {
    p {
      padding: 0.5em;
      color: $gray;

      span {
        font-weight: 600;
      }

      &.expBlock--yellow {
        border-left: 0.2em solid $info;
      }

      &.expBlock--blue {
        border-left: 0.2em solid $success;
      }

      &.expBlock--green {
        border-left: 0.2em solid $secondary;
      }
    }
  }
}

.jobInteractions {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 1rem));
  grid-template-rows: repeat(2, auto);
  grid-gap: 2em;
}
