.feedback {
  font-size: .9em;
  color: $gray;
}

.alert__wrapper {
  max-width: 28rem;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
  display: flex;
  border-radius: .5rem;
  background-color: white;
  position: fixed;
  top: 80px;
  margin: 0 1rem;
  width: calc(100%  - 2rem);
  overflow: hidden;
  z-index: 2;

  .alert__accent {
    width: .5rem;
    background-color: #fed230;
  }

  .alert__content {
    display: flex;
    align-items: center;
    padding: .75rem 0;
    width: 100%;
    // padding: .75rem .5rem;
    // display: flex;
    // align-items: center;

    .alert__icon {
      margin-left: .75rem;
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      background-color: #fed1302f;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 1.4rem;
        height: 1.4rem;
        fill: $primary;
      }
    }

    .message__wrapper {
      margin: 0 .75rem;

      .alert__title {
        font-size: 1.25rem;
        color: $primary;
        font-weight: bold;
        margin: 0 0 .3rem 0;
      }

      .alert__message {
        
      }
    }
  }

  p {
    margin: 0;
  }
}

.alert-enter {
  opacity: 0;
  position: absolute;
  transform: translateY(-110%);
}
.alert-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: all 200ms;
}
.alert-exit {
  opacity: 1;
  position: absolute;
}
.alert-exit-active {
  opacity: 0;
  transform: translateY(-110%);
  transition: all 200ms;
}

.pending__wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    margin: 0 0 1rem 0;
  }

  .circle-1 {
    position: absolute;
    width: 35rem;
    height: 35rem;
    background: linear-gradient(90deg, rgba(37,79,176,1) 0%, rgba(28,39,64,1) 100%);;
    opacity: 0.9;
    border-radius: 100%;
    top: -9rem;
    right: -9rem;
    z-index: 2;
  }
  .circle-2 {
    position: absolute;
    width: 22rem;
    height: 22rem;
    background: linear-gradient(90deg, rgba(255,238,192,1) 0%, rgba(254,199,46,1) 100%);;
    border-radius: 100%;
    top: 15rem;
    right: -10rem;
    z-index: 1;
  }
}