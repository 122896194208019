.accept {
  border: 2px dotted green;
}

.reject {
  background-color: rgba(255, 0, 0, 0.164);
}

.imagePreview {
  position: absolute;
  max-width: 400px;
  max-height: 225px;
  bottom: 5.9rem;
  left: 3.3rem;
  box-shadow: $generic-box-shadow;
  border-radius: $border-radius;
}