.userCard {
  min-height: 184px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;

  .userImage {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 100%;
  }

  .userContent {
    margin-left: 42px;
    flex-grow: 1;

    .name {
      display: flex;
      justify-content: space-between;

      h3 {
        line-height: 1;
        margin: 0;
        color: $primary;
      }
    }

    p {
      color: $gray;

      &.userDesc {
        width: 65%;
        line-height: 1.2;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      &.userInteractions {
        font-size: 12px;
        display: flex;
        span {
          margin: 0 0.8em;
        }
      }
    }
  }
}
