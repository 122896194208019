.adminEdit--wrapper {
  background-color: orange;
  // height: 90vh;
  width: 60vh;
  background-color: white;
  border-radius: $border-radius;
  position: relative;
  padding: 2rem;

  .edit__image {
    display: flex;
    position: relative;
    width: 108px;
    height: 108px;
    margin: 0 auto 32px;
    background: #fec72e24;
    border-radius: 8px;
    color: #fec72e;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-align: center;

    svg {
      width: 18px;
      height: 18px;
      fill: #fec72e;
    }

    &:hover {
      cursor: pointer;
      background: #fec72e50;
    }
  }

  .fileName {
    text-align: center;
    color: #a0a0a0;
    font-size: 12px;
  }
}
