.editor {
    > div {
        margin: 2rem auto;
    }
    img {
        max-width: 100%;
        &.stretched {
            width: 100%;
        }
    }
    iframe {
        min-height: 30vh;
        width: 100%;
        aspect-ratio: 16/9;
    }
    ul, ol {
        padding-left: 2rem;
        list-style-type: initial;
    }
}