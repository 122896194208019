.searchContainer {
  background-color: white;
  border-radius: 10em;
  box-shadow: $box-shadow;
  padding: 10px;
  display: flex;
  margin-bottom: 40px;

  img {
    width: 36px;
    height: 36px;
  }

  input {
    flex-grow: 1;
    background: none;
    border: none;
    font-size: 14px;
    color: $gray;
  }

  .vertSpacer {
    width: 2px;
    margin: 0 .7rem;
    background-color: $background;
  }

  .icon {
    background-color: orange;
  }
}