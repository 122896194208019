:root {
  --bg: #dadce1;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

.claim-item {
  position: relative;
}

.icon-button {
  --button-size: calc(var(--nav-size) * 0.7);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #83838317;
  border-radius: 50%;
  padding: 5px;
  margin: 2px 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
  color: #242425;
  font-weight: bold;
  border: none;

  &.icon-button--nav {
    margin: 0 5px;
  }

  &:hover {
    filter: brightness(0.5);
  }

  svg {
    fill: var(--text-color);
    width: 20px;
    height: 20px;
  }
}

.dropdown {
  position: absolute;
  top: calc(var(--nav-size) * 0.7 + 10px);
  width: 300px;
  right: 0;
  background-color: white;
  border: $generic-border;
  border-radius: $border-radius;
  overflow: hidden;
  transition: height var(--speed) ease;
  box-shadow: $generic-box-shadow;
  z-index: 1;

  &.above {
    bottom: calc(var(--nav-size) * 0.7 + 10px);
    top: inherit;
    left: 0;
  }

  &.list {
    width: 100%;
    z-index: 1;
    height: 20rem;
    overflow: scroll;
  }
}

.drop-content {
  display: flex;
  flex-direction: column;
}

.drop-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  color: #242425;

  .icon-button {
    margin: 0 0.5rem 0 0;

    svg {
      fill: $secondary;
    }

    &:hover {
      fill: $secondary;
    }
  }

  .icon-right {
    margin-left: auto;
    --button-size: calc(var(--nav-size) * 0.7);
    width: var(--button-size);
    height: var(--button-size);
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    color: #242425;
    font-weight: bold;

    &.icon-right--small {
      --button-size: calc(var(--nav-size) * 0.4);
      width: var(--button-size);
      height: var(--button-size);
      svg {
        fill: var(--text-color);
        width: 16px;
        height: 16px;
      }
    }
    &.icon-right--extra {
      margin-left: 0.4rem;
      --button-size: calc(var(--nav-size) * 0.4);
      width: var(--button-size);
      height: var(--button-size);
      svg {
        fill: var(--text-color);
        width: 16px;
        height: 16px;
      }
    }

    &:hover {
      filter: brightness(0.5);
    }

    svg {
      fill: var(--text-color);
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    background-color: #9f9f9f15;
    cursor: pointer;
  }
}

.drop-actions {
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
  font-size: 12px;
  color: $info;

  p {
    transition: all 0.3s ease-in-out;
    &:first-child {
      margin-right: 0.3rem;
    }

    &:hover {
      color: $primary;
    }
  }
}

.drop-item__caption {
  font-size: 12px;
  color: $gray;
  text-transform: capitalize;
  margin-top: 0.5rem;
}

.menu {
  width: 100%;
  padding: 1rem;
}

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit {
}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}
