.companies__wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 2rem;
  width: calc(100% - 2rem);
}

.company__card {
  column-span: 1;
  min-height: 184px;
  background-color: white;
  padding: 42px;
  margin-bottom: 40px;
  box-shadow: $box-shadow;
  border-radius: .9em;
  display: flex;
  align-items: center;

  .company__image {
    height: 100px;
    width: 100px;
    border-radius: 100%;
  }

  .company__title {
    line-height: 1;
    margin: 0 0 .7rem 0;
    color: $primary;
  }

  .company__content {
    margin-left: 42px;
    flex-grow: 1;
    color: $gray;

    .company__locationCount {
      font-size: 12px;
      margin-bottom: .3rem;
    }

    .compant__date {
      font-size: 12px;
    }
  }
}

.company__detailsCard {
  width: 100%;
  padding: 40px;
  background-color: white;
  box-shadow: 0px 13px 33px #00000009;
  margin-bottom: 4em;
  border-radius: .9em;
  max-height: 60vh;
  overflow: hidden;
  position: relative;
}