.messageList {
  margin: 20px 0;
  display: flex;
  flex-direction: column-reverse;
  overflow: scroll;
  height: calc(100% - 380px);
}

.message {
  margin-top: 1rem;
  padding-left: 72px;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-right: 48px !important;
  position: relative;

  .messageContent {
    img {
      position: absolute;
      left: 16px;
      top: 4px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
    }

    h3 {
      margin: 0;
    }
    .messageSender {
      font-size: 1rem;

      span {
        font-size: 0.75rem;
        line-height: 1.375rem;
        color: $gray;
        vertical-align: baseline;
        margin-left: 0.25rem;
      }
    }

    p {
      user-select: text;
      margin-left: -72px;
      padding-left: 72px;
      overflow: hidden;
      font-size: 1rem;
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
  }

  .messageDocs {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 0.25rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    margin-top: 1rem;

    .docWrapper {
      border-left: 4px solid $secondary;
      background: rgba(221, 221, 221, 0.144);
      align-self: start;
      justify-self: start;
      max-width: 432px;
      border-radius: 3px;
      overflow: hidden;

      .content {
        padding: 0.5rem 1rem 1rem 0.75rem;
        display: inline-grid;
        grid-template-columns: auto;
        grid-template-rows: auto;

        .platform {
          font-size: 0.75rem;
          line-height: 1rem;
          font-weight: 400;
          grid-column: 1/1;
          margin-top: 8px;
        }

        .title {
          font-size: 1rem;
          font-weight: 600;
          display: inline-block;
          grid-column: 1/1;
          margin-top: 8px;
          color: $primary;
        }

        .video {
          width: 400px;
          height: 225px;
          margin-top: 16px;
          grid-column: 1/1;
          border-radius: 4px;
          contain: paint;
          // background-image: url('https://picsum.photos/400/400');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
}

.message__log {
  text-align: center;
  color: #a0a0a0;
  font-size: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
