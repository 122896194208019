.createLocCard {
  @include contentBlock;

  .locForm {
    columns: span 1;
  }

  .locImage--preview {
    columns: span 1;
    width: 100%;
    height: 100%;
  }

  .locationType {
  }
}

.graf--layoutOutsetLeft {
  margin-left: 0;
}

.locImage {
  position: relative;
  columns: span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 20rem;
  text-align: center;
  background: #fafafa;
  border: 1px dashed $primary;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &--location {
    flex-direction: column;
  }

  .error {
    font-size: 0.875rem;
    color: red;
    margin-top: 1rem;
  }
  &.marginBottom {
    margin-bottom: 64px;
  }
}

.image__blur {
  padding: 1rem;
  border-radius: 2rem;
  backdrop-filter: blur(8px) brightness(0.8);
  color: white;
}

.locations__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.address-dropdown {
  position: absolute;
  background-color: orange;
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #e1dfe9;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: $box-shadow;
  z-index: 1;

  .address-dropdown__item {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 1rem;
    color: #242425;

    .address-dropdown__item__address {
      font-weight: bold;
      // overflow: hidden;

      span {
        color: #808291;
        font-weight: lighter;
        font-size: 0.775rem;
      }
    }

    .dropdown__icon {
      margin: 0 0.375rem 0 0;
      width: 22px;
      height: 22px;
    }

    &--active {
      background-color: #4875f3;
      color: white !important;
      cursor: pointer;

      .address-dropdown__item__address {
        color: white;
      }
    }
  }
}
