@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap');

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  font-family: 'Rubik', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1;
  color: #1c2740;
  background-color: #fafafa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.appWrapper {
  display: grid;
  grid-template-columns: 18% auto 20%;
}

.contentWrapper {
  grid-column: 2;
  width: 100%;
  min-height: 100vh;
  background-color: $background;
  padding: 2.2rem 35px;

  &.closed {
    grid-column: span 3;
    padding: 0;
  }

  @include mq('992px') {
    grid-column: span 3;
    // padding: 0;
  }
}

.horizontalDivider {
  width: 100%;
  height: 1px;
  background-color: $lightgray;
  margin: 2em 0;

  &.noMargin {
    margin: 0;
  }
}

a {
  text-decoration: none;
  color: none;
}

.pageTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.grid {
  display: grid;
  grid-template-columns: calc(50% - 1em) calc(50% - 1em);
  grid-template-rows: repeat(2, auto);
  grid-gap: 2em;
}

.flex {
  display: flex;
}
.centerAlign {
  justify-content: center;
  align-items: center;
}

.css-2b097c-container {
  .css-yk16xz-control {
    padding: 5.3px;
    border-radius: 10em;

    .css-1wa3eu0-placeholder {
      font-size: 14px;
    }
  }
}

.codex-editor__redactor {
  padding-bottom: 0 !important;
}