.formContainer {
  display: grid;
  grid-template-columns: calc(50% - 1em) calc(50% - 1em);
  grid-gap: 2em;
  position: relative;

  &.noGrid {
    display: flex;
  }

  &.formContainer--bottom {
    width: calc(100% - 80px);
    position: absolute;
    bottom: 10px;
  }

  .formGroup {
    grid-column: span 2;
    margin-bottom: 25px;
    flex-grow: 1;
    align-items: center;
    position: relative;

    &.inline {
      // display: flex;
      grid-column: span 1;
    }

    &.noMargin {
      margin: 0;
    }

    &.formGroup--withBtn {
      display: flex;

      button {
        margin-left: 2em;
      }
    }

    label {
      display: block;
      font-weight: 400;
      margin-bottom: 1em;
      margin-left: 3px;
      font-size: 0.9em;
      text-align: left;
      color: $gray;

      &.bold {
        color: $primary;
        font-weight: bold;
      }
    }

    input {
      width: 100%;
      padding: 16px;
      border-radius: 10em;
      border: 1px solid #ddd;
    }

    select {
      width: 100%;
      padding: 16px;
      border-radius: 10em;
      border: 1px solid #ddd;
      // object-fit: none;
      // border: none;
      // color: $primary;
      // font-weight: 600;
      // font-size: 17px;
      // background: transparent;
      // appearance: none;
    }

    .input__error {
      font-size: 12px;
      color: $danger;
      margin: 0.6rem 0 0 1rem;
    }
  }

  .toggle {
    box-sizing: border-box;
    font-size: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    input {
      @include hideInput;
    }
    input + label {
      margin: 0;
      padding: 0.75rem 1.5rem;
      box-sizing: border-box;
      position: relative;
      display: inline-block;
      border: solid 1px #ddd;
      background-color: #fff;
      font-size: 0.9rem;
      line-height: 140%;
      text-align: center;
      box-shadow: 0 0 0 rgba(255, 255, 255, 0);
      transition: border-color 0.15s ease-out, color 0.25s ease-out,
        background-color 0.15s ease-out, box-shadow 0.15s ease-out;

      &:first-of-type {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-right: none;
      }
      &:last-of-type {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-left: none;
      }
    }
    input:hover + label {
      border-color: $gray;
    }
    input:checked + label {
      background-color: $secondary;
      color: #fff;
      box-shadow: $activeShadow;
      border-color: $secondary;
      z-index: 1;
    }
    input:focus + label {
      @include focusOutline;
    }

    @include breakpoint(800) {
      input + label {
        padding: 0.75rem 0.25rem;
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .salaryContainer {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 2em;
  }
}
