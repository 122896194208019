.sidebarContainer {
  grid-column: 1;
  width: 18%;
  height: 100vh;
  background: white;
  padding: 44px;
  position: fixed;

  .siderbar-logo {
    width: 3em;
    height: 3em;
    border-radius: 50%;
  }

  .sidebar-navGroup {
    margin: 2.6em 0;

    ul {
      display: flex;
      flex-direction: column;
    }
    .sidebar-navItem {
      font-size: 17px;
      margin: 0.2em 2em;
      padding: 0.5em 0;
      color: $gray;
      font-weight: 500;

      &.sidebar-navItem--active {
        color: $secondary;
      }
    }

    &--bottom {
      position: absolute;
      bottom: 2rem;
    }
  }

  // Container Media Queries
  @include mq('992px') {
    width: 0;
    display: none;
  }
}
