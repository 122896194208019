.modalBackground {
  opacity: 0;
  z-index: -1;
  transition: opacity 0.4s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px) contrast(.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalInner {
  @include contentBlock;
  width: 80%;
  height: 90%;
  position: relative;
}

.modalShowing--true {
  opacity: 1;
  z-index: 999;
}