.btnPrimary {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  max-height: 50px;
  position: relative;
  padding: 1.3em 2em;
  color: white;
  background-color: $secondary;
  border: none;
  border-radius: 10em;
  transition: all 0.3s ease-in;

  &.fullWidth {
    grid-column: span 2;
    width: 100%;
  }

  &:disabled {
    background-color: #fec72e71;
    cursor: not-allowed;
  }

  &.fit {
    padding: 0em 2em;
    height: 42px;
  }

  // &.loading {
  //   opacity: .5;
  // }

  &:hover {
    background-color: #efbc2e;
    cursor: pointer;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 0.675rem;
    fill: white;
  }
}

.btnSecondary {
  padding: 1em 2em;
  color: white;
  background-color: $primary;
  border: none;
  border-radius: 10em;
}

.btnGroup {
  display: flex;

  button {
    margin-right: 0.9em;
  }
}
