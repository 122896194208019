.authPage__wrapper {
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  .auth__container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 960px;

    .auth__content {
      flex-shrink: 0;
      width: 240px;
      margin-right: auto;

      h3 {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 27px;
      }

      p {
        margin-bottom: 32px;
        margin-right: -20px;
        font-size: 16px;
        font-weight: 700;
      }

      .socials__container {
        margin-bottom: 22px;

        .social__item {
          margin-bottom: 16px;
          background: rgba(27, 29, 33, 0.1);
          display: flex;
          align-items: center;
          border-radius: 16px;
          font-weight: 500;
          transition: all 0.25s;
          overflow: hidden;

          .social__image {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            width: 72px;
            height: 72px;
            border-radius: 16px;

            svg {
              width: 2rem;
              height: 2rem;
              fill: red;
            }
          }

          .social__details {
            padding: 10px 12px 10px 24px;

            .social__title {
              margin-bottom: 2px;
              margin-top: 0;
              font-size: 13px;
            }

            .social__body {
              margin-bottom: 4px;
              font-size: 12px;
              color: #808191;
            }
          }
        }
      }
    }

    .auth__form {
      position: relative;
      width: 464px;
      padding: 48px 48px 64px;
      border-radius: $border-radius;
      background: white;

      h3 {
        font-size: 40px;
        line-height: 1.4;
        font-weight: 600;
        // margin-bottom: 20px;
        margin: 0 0 20px 0;
      }

      .auth__ctxMessage {
        display: flex;
        align-items: center;
        margin-bottom: 42px;
        font-size: 12px;
        font-weight: 500;
        color: $gray;

        span {
          margin-left: 0.5rem;
          color: #3f8cff;
        }
      }

      .divider {
        width: 100%;
        border: thin solid #ececec;
        margin-bottom: 1.3rem;
        margin-top: 1.3rem;
      }

      .alt__links {
        margin-top: 1rem;
        text-align: center;

        a {
          color: #242425;
          transition: all 0.1s ease-in;

          &:hover {
            color: $secondary;
          }
        }
        span {
          margin: 0 0.5rem;
        }
      }
    }
  }
}
