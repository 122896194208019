@mixin mq($from) {
  @media (max-width: $from) {
    @content;
  }
}

@mixin contentBlock {
  width: 100%;
  padding: 40px;
  background-color: white;
  box-shadow: $box-shadow;
  margin-bottom: 4em;
  border-radius: .9em;
}

/* MIXINS */
@mixin focusOutline {outline: dotted 1px #CCC; outline-offset: .45rem;}
@mixin hideInput {width: 0; height: 0; position: absolute; left: -9999px;}
@mixin breakpoint($point) {
	@if $point == 1100 {
		@media (max-width: 1100px) { @content ; }
	}
	@else if $point == 800 {
		@media (max-width: 800px) { @content ; }
	}
}