$generic-box-shadow: 0px 13px 33px #00000029;
$generic-border: 1px solid #E3E3E3;
$generic-border--dotted: 2px dotted #E3E3E3;

// CI Colors
$primary: #1C2740;
$secondary: #FEC72E;
$gray: #9F9F9F;
$lightgray: #9f9f9f27;

// Feedback Colors
$danger: #FD5756;
$warning: #FEC72E;
$success: #03D859;
$info: #3597EC;

// App Colors
$background: #FAFAFA;

// Shadows
$box-shadow: 0px 13px 33px #00000009;

// CSS Attributes
$border-radius: .9em;

$activeShadow: 0 0 10px rgba($secondary, .5);
