.profileCard-container {
  grid-column: 3;
  width: 20%;
  height: 100vh;
  background: white;
  align-self: end;
  padding: 44px;
  position: fixed;
  top: 0;
  right: 0;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @include mq('992px') {
    width: 0;
    display: none;
  }

  .profileSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s ease-in-out;

    &:hover {
      cursor: pointer;

      h3 {
        color: #273453be;
      }
    }

    img {
      width: 3em;
      height: 3em;
      border-radius: 100%;
    }

    h3 {
      span {
        font-weight: 200;
      }
    }
  }

  .statsSection {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    grid-gap: 1em;

    .statContainer {
      grid-column: span 1;
      grid-row: span 1;

      p {
        font-size: 12px;
        color: $gray;
      }

      h3 {
        padding: 0.5em;

        &.statBlock--jobs {
          border-left: 0.2em solid $info;
        }

        &.statBlock--views {
          border-left: 0.2em solid $success;
        }

        &.statBlock--diggs {
          border-left: 0.2em solid $secondary;
        }

        &.statBlock--placed {
          border-left: 0.2em solid $danger;
        }
      }
    }
  }
}

.footer__title {
  font-size: 14px;
  color: #a0a0a0;
  margin: 0 0 1rem 0;
}

.company__widget {
  width: 100%;
  padding: 1.475em;
  border: 1px solid $lightgray;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin: 0;
  }

  .widget__header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    img {
      width: 2rem;
      height: 2rem;
      object-fit: cover;
      background-color: $lightgray;
      margin-right: 0.5rem;
      border-radius: 50%;
    }
  }

  .footer__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    p {
      font-size: 12px;
      color: $gray;
    }

    h3 {
      padding: 0.5em;

      &.statBlock--jobs {
        border-left: 0.2em solid $info;
      }

      &.statBlock--views {
        border-left: 0.2em solid $success;
      }

      &.statBlock--diggs {
        border-left: 0.2em solid $secondary;
      }

      &.statBlock--placed {
        border-left: 0.2em solid $danger;
      }
    }
  }
}

.ctx__area {
  display: flex;
  flex-direction: column;
}

.jobShortcut {
  width: 100%;
  border-left: 0.2em solid #3597ec;
  padding: 0.5em;
  font-weight: 600;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    p {
      color: $info;
    }
  }

  p {
    margin: 0;
  }

  .caption {
    font-size: 12px;
    color: #a0a0a0;
    margin-top: 0.3rem;
    font-weight: 400;
    text-transform: capitalize;
  }
}
