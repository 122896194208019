.jobCard {
  min-height: 184px;
  width: 100%;
  background-color: white;
  padding: 42px;
  margin-bottom: 40px;
  box-shadow: $box-shadow;
  border-radius: 0.9em;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &.withGrid {
    column-span: 2;
  }

  &.outlined {
    border: $generic-border--dotted;
    justify-content: center;
    color: $primary;

    &:hover {
      transform: scale(1.01);
      border-color: $secondary;
    }

    .job__feedback {
      text-align: center;

      svg {
        height: 2rem;
      }
    }
  }

  .jobImage {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 100%;
  }

  .jobContent {
    margin-left: 42px;
    flex-grow: 1;

    .jobTitle {
      display: flex;
      justify-content: space-between;

      h3 {
        line-height: 1;
        margin: 0;
        color: $primary;
      }
    }

    .jobDesc {
      width: 65%;
      min-height: 30px;
      max-height: 30px;
      line-height: 1.2;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      color: $gray;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;

      .graf--p,
      .graf--blockquote,
      .graf--pullquote {
        margin-bottom: 0px;
      }
    }
    .jobInteractions {
      color: $gray;
      font-size: 12px;
      display: flex;
      span {
        margin: 0 0.8em;
      }
    }
  }
}
